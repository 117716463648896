import styled from 'styled-components'
import '../css/Home.scss'
import '../App.scss';


function Home() {

    const Wrapper = styled.div`
        background: rgb(200,177,236);
        background: linear-gradient(90deg, rgba(200,177,236,1) 0%, rgba(157,197,199,1) 50%, rgba(98,225,147,1) 100%);
        overflow: hidden;
    `

    const Container = styled.div`
        height: 100vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    `

    return (
        <Wrapper>
            <Container>
                <h1 className="blink-text">Patience</h1>
                <h1>The Joiful Foundation is coming soon</h1>
            </Container>
        </Wrapper>
    )

}

export default Home

